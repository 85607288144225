import { Product } from '@commerce/types/product'
import { Fitter } from '@generated/model/fitter'
import { createContext, useContext } from 'react'
// import { MetaDataType } from 'components/metaData/MetaData'
// import { GlobalContentContext as GlobalContentContextType } from '@generated/model/globalContentContext'

export type GlobalContentContextType = {
  loretwo_product?: Product
  social_media: { [key: string]: string } | undefined
  analytics: { [key: string]: string } | undefined
  site_meta_data: {
    meta_title?: string
    meta_description?: string
    meta_image?: string
  }
  footer: {
    detail?: string
    copywrite?: string
  }
  nav_image: {
    image_height: number
    image_width: number
    image: string
    mobile_image_height?: number
    mobile_image_width?: number
    mobile_image?: string
    alt_text?: string
  }
  sidebar: {
    find_fitter_sidebar_title: string
    find_fitter_sidebar_detail: string
    find_fitter_sidebar_suggest_detail: string
    fitters: Fitter[]
  }
}

export type GlobalContentContextStateType = {
  data: GlobalContentContextType
  setData: (data: GlobalContentContextType) => void
}

export const GlobalContentContext =
  createContext<GlobalContentContextStateType>({
    data: {
      analytics: {},
      social_media: {},
      site_meta_data: {
        meta_title: '',
        meta_description: '',
        meta_image: '',
      },
      footer: {
        detail: '',
        copywrite: '',
      },
      nav_image: {
        image_height: 0,
        image_width: 0,
        image: '',
      },
      sidebar: {
        find_fitter_sidebar_title: '',
        find_fitter_sidebar_detail: '',
        find_fitter_sidebar_suggest_detail: '',
        fitters: [],
      },
    },
    setData: (val) => {},
  })

export const useGlobalContentContext = (): GlobalContentContextStateType =>
  useContext(GlobalContentContext)
